import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Judges from "../components/judges"

const SponsorsPage = () => (
  <Background>
    <Layout>
      <Seo title="Sponsors" />
      <Container>
        <PageTitle text="Our Judges" />
        <Judges />
      </Container>
    </Layout>
  </Background>
)

export default SponsorsPage
